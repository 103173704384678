<template>
  <div class="table">
    <table>
      <colgroup>
        <col v-for="(header ,idx) in headers" :key="`col-${idx}`" :style="{'width' : (header.col ? header.col : 'auto')}">
      </colgroup>
      <thead>
      <tr>
        <th v-for="(header ,idx) in headers" :key="`th-${idx}`" class="text-center" :class="{'cell-check': (header.type === 'check')}">
          <div v-if="header.type === 'check'" class="form-check"><input type="checkbox" class="form-check-input" @change="checkedAll($event, header.key)"></div>
          <span v-else-if="header.divide" class="table-th">{{ header.title }}<br>{{ header.title2 }}</span>
          <span v-else class="table-th">{{ header.title }}</span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="bodies.length === 0">
        <td :colspan="headers.length">
          <div class="table-td" >
            <span class="text text-truncate">{{ noResult ? noResult : '데이터가 존재하지 않습니다.'}}</span>
          </div>
        </td>
      </tr>
      <tr v-for="(body, idx) in bodies" :key="`tr-${idx}`">
        <template v-for="(header, i) in headers">
          <td v-if="!header.type" :key="`td-${idx}-${i}`" :class="{'text-left' : !!header.left, 'text-right': header.right }">
            <div class="table-td">
              <span class="text text-truncate" :title="body[header.key]">{{ body[header.key] }}</span>
            </div>
          </td>
          <th v-else-if="header.type === 'check'" :key="`td-${idx}-${i}`" class="cell-check">
            <div class="form-check">
              <input v-model="checked" type="checkbox" class="form-check-input" :value="body[header.key]">
            </div>
          </th>
          <th v-else-if="header.type === 'sequence'" :key="`td-${idx}-${i}`" class="cell-id">
            <span class="text">{{ isPaging ? (totalCount - ((pageNo - 1) * pageSize) - idx) : (idx + 1) }}</span>
          </th>
          <td v-else-if="header.type === 'image'" :key="`td-${idx}-${i}`" class="cell-image">
            <div class="image-box">
              <img v-if="body[header.key]" :src="body[header.key]" alt="">
              <img v-else src="../assets/img/community/reply_unknown_icon.svg" alt="">
            </div>
          </td>
          <td v-else-if="header.type === 'link'" :key="`td-${idx}-${i}`" :class="{'text-left' : !!header.left}">
            <div class="table-td">
              <a :href="body[header.key] ? body[header.key] : 'javascript:'" class="cell-link">
                <span class="text text-truncate">{{ body[header.key2] }}</span>
              </a>
            </div>
          </td>
          <td v-else-if="header.type === 'event'" :key="`td-${idx}-${i}`" :class="{'text-left' : !!header.left}">
            <div class="table-td">
              <a href="javascript:" class="cell-link" @click="clickEvent(idx)">
                <span class="text text-truncate">{{ body[header.key] }}</span>
              </a>
            </div>
          </td>
          <td v-else-if="header.type === 'tag'" :key="`td-${idx}-${i}`" :class="{'text-left' : !!header.left}">
            <div class="table-td">
              <ul class="tag-list">
                <li v-if="body[header.key]" class="tag-item"><span class="text">{{ body[header.key] }}{{ body[header.key2] ? ',' : '' }}</span></li>
                <li v-if="body[header.key2]" class="tag-item"><span class="text">{{ body[header.key2] }}{{ body[header.key3] ? ',' : '' }}</span></li>
                <li v-if="body[header.key3]" class="tag-item"><span class="text">{{ body[header.key3] }}</span></li>
              </ul>
            </div>
          </td>
          <td v-else-if="header.type === 'date'" :key="`td-${idx}-${i}`">
            <div class="table-td">
              <span class="text">{{ header.ymd ? getDateToFormat(body[header.key], header.format) : getTimestampToFormat(body[header.key], header.format) }}</span>
            </div>
          </td>
          <td v-else-if="header.type === 'range'" :key="`td-${idx}-${i}`">
            <div class="table-td">
              <span class="text">{{ getDateToFormat(body[header.key], header.format, header.timestamp) }}<br>~  {{ getDateToFormat(body[header.key2], header.format, header.timestamp) }}</span>
            </div>
          </td>
          <td v-else-if="header.type === 'status'" :key="`td-${idx}-${i}`" class="cell-status">
          <!-- is-apply : 초록, is-rejected : 빨강, is-approval: 파랑-->
            <div class="status" :class="(header.colors[body[header.key]] || 'is-rejected')">
              <span class="text">{{ body[header.key2] }}</span>
            </div>
          </td>

          <td v-else-if="header.type === 'button'" :key="`td-${idx}-${i}`">
            <div class="table-td">
<!--              <button type="button" class="btn btn-sm" :class="`btn-${header.color ? header.color : 'default'}`" @click="clickButton(idx, header.seq)" :disabled="isDisabled(body, header.isDisabled)">-->
              <button type="button" class="btn btn-sm" :class="buttonColor(body, header.color)" @click="clickButton(idx, header.seq)" :disabled="isDisabled(body, header.isDisabled)">
                <span class="text" :class="textColor(body, header.color2)">{{ buttonName(body, header.name) }}</span>
              </button>
            </div>
          </td>

          <td v-else-if="header.type === 'switch'" :key="`td-${idx}-${i}`">
            <div class="table-td">
              <div class="form-switch-group" :class="{'is-active': (body[header.key] === 'Y' || body[header.key] === true)}">
                <span class="form-switch-status">{{ (body[header.key] === 'Y' || body[header.key] === true) ? statusTextY : statusTextN }}</span>
                <label class="form-switch">
                  <!-- key -> 상태값 key2 -> 현재 상태키 -->
                  <input type="checkbox" class="form-switch-input" :checked="(body[header.key] === 'Y' || body[header.key] === true)" @change="changeSwitch($event, idx)">
                  <span class="form-switch-slider"></span>
                </label>
              </div>
            </div>
          </td>

          <td v-else-if="header.type === 'convert'" :key="`td-${idx}-${i}`">
            <div class="table-td">
              <div class="form-switch-group" :class="{'is-active': (body[header.key] === header.trueObj.value)}">
                <span class="form-switch-status">{{ (body[header.key] === header.trueObj.value) ? header.trueObj.name : header.falseObj.name }}</span>
                <label class="form-switch">
                  <input type="checkbox" class="form-switch-input" :checked="(body[header.key] === header.trueObj.value)" @change="changeSwitch($event, idx)">
                  <span class="form-switch-slider"></span>
                </label>
              </div>
            </div>
          </td>

          <td v-else-if="header.type === 'star'" :key="`td-${idx}-${i}`">
            <div class="table-td">
              <div class="top-rating rating-wrapper rating-sm">
                <div class="rating">
                  <span class="star" :class="{'is-active': (body[header.key] >= 1)}"></span>
                  <span class="star" :class="{'is-active': (body[header.key] >= 2)}"></span>
                  <span class="star" :class="{'is-active': (body[header.key] >= 3)}"></span>
                  <span class="star" :class="{'is-active': (body[header.key] >= 4)}"></span>
                  <span class="star" :class="{'is-active': (body[header.key] >= 5)}"></span>
                </div>
                <div class="rating-text">
                  <span class="text">{{ getFixedFormat(body[header.key], 1) }}</span>
                </div>
              </div>
            </div>
          </td>
          <td v-else-if="header.type === 'code'" :key="`td-${idx}-${i}`" :class="{'text-left' : !!header.left}">
            <div class="table-td">
              <span class="text text-truncate">{{ getCodeName(body[header.key], header.column, header.division) }}</span>
            </div>
          </td>
          <td v-else-if="header.type === 'phone'" :key="`td-${idx}-${i}`" :class="{'text-left' : !!header.left}">
            <div class="table-td">
              <span class="text text-truncate" >{{ getPhoneMask(body[header.key]) }}</span>
            </div>
          </td>

          <td v-else-if="header.type === 'size'" :key="`td-${idx}-${i}`" :class="{'text-left' : !!header.left}">
            <div class="table-td">
              <span class="text text-truncate">{{ getConvertToStorageBytes(body[header.key]) }}</span>
            </div>
          </td>

          <td v-else-if="header.type === 'custom'" :key="`td-${idx}-${i}`" :class="{'text-left' : !!header.left}">
            <div class="table-td">
              <span class="text text-truncate" :title="header.func(body)">{{ header.func(body) }}</span>
            </div>
          </td>

          <td v-else-if="header.type === 'case-button'" :key="`td-${idx}-${i}`" :class="{'text-left' : !!header.left}">
            <div class="table-td">
              <button v-if="header.func(body)" type="button" class="btn btn-sm" :class="buttonColor(body, header.color)" @click="clickButton(idx, header.seq)" :disabled="isDisabled(body, header.isDisabled)">
                <span class="text" :class="textColor(body, header.color2)">{{ buttonName(body, header.name) }}</span>
              </button>
              <span v-else class="text text-truncate">-</span>
            </div>
          </td>
        </template>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import {convertToStorageBytes, phoneNumberMask, timestampToDateFormat, ymdToDateFormat} from '../assets/js/utils';

export default {
  name: 'PortalTable',
  props: {
    headers: {
      type: Array,
      default: []
    },
    bodies: {
      type: Array,
      default: []
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    pageNo: {
      type: Number,
      default: 0,
    },
    buttonFunc: Function,
    buttonFunc2: Function,
    buttonFunc3: Function,
    buttonFunc4: Function,
    buttonFunc5: Function,
    buttonFunc6: Function,
    eventFunc: Function,
    switchFunc: Function,
    noResult: String,
    statusTextY: {
      type: String,
      default: '공개'
    },
    statusTextN: {
      type: String,
      default: '비공개'
    },
  },
  data: () => ({
    checked: []
  }),
  computed: {
    ...mapGetters('common', ['shopMallCodeItems', 'productItems', 'commonCode']),
    isPaging(){
      return (this.totalCount > 0 && this.pageNo > 0);
    }
  },
  methods: {
    getTimestampToFormat(target, format){
      if(target){
        return timestampToDateFormat(target, (format || 'yyyy-MM-dd, hh:mm'));
      }
      return '-';
    },
    getDateToFormat(target, format, isTimestamp){
      if(isTimestamp){
        return timestampToDateFormat(target, (format || 'yyyy-MM-dd'));
      }else{
        return ymdToDateFormat(target, (format || 'yyyy-MM-dd'));
      }
    },
    // getYmdToDateFormat(target, format){
    //   return ymdToDateFormat(target, (format || 'yyyy-MM-dd'));
    // },
    clickButton(idx, seq){
      if(seq > 1){
        if(this[`buttonFunc${seq}`] instanceof Function) this[`buttonFunc${seq}`](idx);
      }else{
        if(this.buttonFunc instanceof Function) this.buttonFunc(idx);
      }
    },
    clickEvent(idx){
      if(this.eventFunc instanceof Function) this.eventFunc(idx);
    },
    changeSwitch(e, idx){
      if(this.switchFunc instanceof Function) this.switchFunc(e, idx);
    },
    getRange(target){
      const result = [];
      const max = 5;
      if(target >= 0){
        if(target > max) target = max;
        for(let i = 0; i < target; i++){
          result.push(1);
        }
        for (let i = 0; i < (5 - target); i++){
          result.push(0);
        }
      }
      return []
    },
    getFixedFormat(target, i){
      if(target >= 0) return target.toFixed(i);
      return '0.0';
    },
    checkedAll(e, key){
      if(e.target.checked){
        this.checked = this.bodies.map(x => x[key]);
      }else{
        this.checked = [];
      }
    },
    isDisabled(body, disabledFunc){
      if(disabledFunc instanceof Function) return disabledFunc(body);
      return false;
    },
    buttonName(body, name){
      if(name instanceof Function){return name(body);}
      return name;
    },
    buttonColor(body, color){
      if(color instanceof Function){
        return color(body);
      }
      return `btn-${color ? color : 'default'}`;
    },
    textColor(body, color){
      if(color instanceof Function) return color(body);
      return color;
    },
    getCodeName(code, column, division){
      if(division === 'cd'){
        const filtered = this.shopMallCodeItems.filter(x => (x.cmmnCd === column && x.cmmnCdVal === code))
        return filtered.length > 0 ? filtered[0].cmmnCdValNm : '';
      }else if(division === 'cls'){
        const filtered = this.productItems.filter(x => (x.gdsClsId === code));
        return filtered.length > 0 ? filtered[0].clsNm : '';
      }else if(division === 'dcd'){
        const filtered = this.commonCode.filter(x => (x.dcd === code));
        return filtered.length > 0 ? filtered[0].dcdVal : '';
      }
      return '';
    },
    getConvertToStorageBytes(size){
      return convertToStorageBytes(size);
    },
    getPhoneMask(phoneNo){
      return phoneNumberMask(phoneNo);
    }
  }
}
</script>
