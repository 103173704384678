<template>
  <main class="page-sme-noticeinfo" id="sme-campaign">
    <div class="page-header">
      <div class="page-component">
        <div class="page-header-title">
          <h2 class="title">공지사항</h2>
        </div>
      </div>
    </div>
    <div class="page-body bg-white" data-fill="true">
      <nav class="page-nav">
        <ul class="nav-list page-component">
          <li class="nav-item" :class="{ 'is-selected': ntcCtgrDcd === '' }">
            <router-link :to="{ name: 'Notice' }"><span class="nav-text">전체</span></router-link>
          </li>
          <li v-for="(item, idx) in ctgrItems" class="nav-item" :key="idx" :class="{ 'is-selected': item.dcd === ntcCtgrDcd }">
            <router-link :to="{ name: 'Notice', query: { ctgrCode: item.dcd } }" class="nav-link">
              <span class="nav-text">{{ item.dcdVal }}</span>
            </router-link>
          </li>
        </ul>
      </nav>

      <!-- search start -->
      <form onsubmit="return false">
        <div class="page-body page-bg" data-fill="true">
          <div class="page-component">
            <div class="post-list-wrapper">
              <div class="notice-list-wrapper">
                <div class="box_search">


                  <template v-if="isMobile">
                    <!-- 220531 modify -->
                    <div class="header-column box_calendar">
                      <p class="calendar">
                        <label for="" class="blind">검색시작일자 입력. 날짜형식:YYYY-MM-DD</label>
                        <date-picker style="font-weight: 400" v-model="startYmd" class="gachi-datepicker-width" input-class="search_box" placeholder="연도-월-일" />
                      </p>
                      <span class="ms-1 me-1">~</span>
                      <p class="calendar">
                        <label for="" class="blind">검색시작일자 입력. 날짜형식:YYYY-MM-DD</label>
                        <date-picker style="font-weight: 400" v-model="endYmd" class="gachi-datepicker-width" input-class="search_box" placeholder="연도-월-일" />
                      </p>
                    </div>
                    <div class="filter-selector">
                      <select v-model="ntcTag" class="form-select form-sm" style="font-weight: 400">
                        <option value="">전체</option>
                        <option value="0">제목</option>
                        <option value="1">내용</option>
                      </select>
                    </div>
                    <div class="header-column box_entry">
                      <div class="search_box" @keyup.enter="getNoticeList(true)">
                        <label for="" class="blind">검색어입력</label>
                        <input v-model="ntcTit" type="text" id="" placeholder="검색어를 입력하세요" />
                        <button type="button" class="btn btn-primary btn-sm search-button pe-4" @click="getNoticeList(true)">
                          <span class="text">검색</span>
                        </button>
                      </div>
                    </div>
                    <!-- // 220531 modify -->
                  </template>


                  <template v-else>
                    <div class="header-column box_calendar">
                      <p class="calendar">
                        <label for="" class="blind">검색시작일자 입력. 날짜형식:YYYY-MM-DD</label>
                        <date-picker ref="startYmd"  style="font-weight: 400" v-model="startYmd" class="gachi-datepicker-width" input-class="search_box" placeholder="연도-월-일" />
                      </p>
                      <span class="ms-2 me-2">~</span>
                      <p class="calendar">
                        <label for="" class="blind">검색시작일자 입력. 날짜형식:YYYY-MM-DD</label>
                        <date-picker style="font-weight: 400" v-model="endYmd" class="gachi-datepicker-width" input-class="search_box" placeholder="연도-월-일" />
                      </p>
                    </div>
                    <div class="header-column">
                      <div class="filter-selector">
                        <select v-model="ntcTag" class="form-select form-sm" style="font-weight: 400">
                          <option value="">전체</option>
                          <option value="0">제목</option>
                          <option value="1">내용</option>
                        </select>
                      </div>
                    </div>
                    <div class="header-column">
                      <div class="search_box" @keyup.enter="getNoticeList">
                        <label for="" class="blind">검색어입력</label>
                        <input v-model="ntcTit" type="text" id="" placeholder="검색어를 입력하세요" />
                        <button type="button" class="btn btn-primary btn-sm search-button pe-4" @click="getNoticeList">
                          <span class="text">검색</span>
                        </button>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!-- search end -->

      <notice-list :div-class="['page-component']" :is-loading="isLoading" :is-no-result="isNoResult">
        <div class="post-list-wrapper notice-list-wrapper">
          <div class="notice_body">
            <div class="notice_body-header">
              <div class="header_left">
                <p class="all_page">
                  전체 : <span> {{ totalCount }}</span> 건
                </p>
                <p class="current_page">
                  현재 페이지 : <span> {{ totalCount % pageSize === 0 ? 0 : pageNo }} </span> /{{ totalCount % pageSize === 0 ? totalCount / pageSize : parseInt(totalCount / pageSize + 1) }}
                </p>
              </div>
              <div class="header-column">
                <div class="filter-selector">
                  <select v-model="sortCd" name="" id="" class="form-select" required="" @change="getNoticeList(true)" style="font-weight: 400; color: black">
                    <option value="">최신순</option>
                    <option value="I">조회순</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="notice_body-list">
              <notice-card :items="refineItems" :is-tit="true" />
            </div>
          </div>
        </div>
      </notice-list>
      <portal-pagination page-name="Notice" :page-no="pageNo" :page-size="pageSize" :total-count="totalCount" :query="queries"></portal-pagination>
    </div>
  </main>
</template>

<script>
import { ACT_GET_COMMON_CODE_LIST, ACT_GET_NOTICE2_LIST } from '../../../store/_act_consts';
import { MUT_SHOW_ALERT } from '../../../store/_mut_consts';
import { getItems, lengthCheck, queryToValue, setPaging, tryResCallback, timestampToDateFormat } from '../../../assets/js/utils';
import { mapGetters } from 'vuex';
import NoResult from '../../../components/common/NoResult';
import NoticeCard from '../../../components/common/NoticeCard';
import NoticeList from '../../../components/common/NoticeList';
import PortalPagination from '../../../components/PortalPagination';
import PortalTable from '../../../components/PortalTable';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'Notice',
  components: {
    NoticeCard,
    NoResult,
    NoticeList,
    PortalPagination,
    PortalTable,
    DatePicker,
  },
  data: () => ({
    isLoading: false,
    hasMore: false,
    totalCount: 0,
    pageSize: 10,
    pageNo: 1,
    sortCd: '',
    ntcTit: '',
    ntcTag: '',
    regDt: '',
    ntcCtgrDcd: '',
    atchFileNm: '',
    atchFilePtUrl: '',
    fileExt: '',
    startYmd: '',
    endYmd: '',
    items: [],
    ctgrItems: [],
    tryCount: 0,
    inqCnt: 0,
    upporFixYn: '',
    row: '',
    type: 'text',
  }),
  watch: {
    $route(to) {
      this.pageNo = queryToValue(to.query.pageNo, true);
      this.totalCount = queryToValue(to.query.totalCount, true);
      this.keyword = queryToValue(to.query.keyword, false, '');
      this.ntcCtgrDcd = queryToValue(to.query.ctgrCode, false, '');
      this.ntcTit = queryToValue(to.query.ntcTit, false);
      this.ntcTag = queryToValue(to.query.tag, false);
      if (queryToValue(to.query.startYmd, false)) this.startYmd = new Date(queryToValue(to.query.startYmd, false));
      if (queryToValue(to.query.endYmd, false)) this.endYmd = new Date(queryToValue(to.query.endYmd, false));
      this.getNoticeList(true);
    },
    endYmd: function(date){
      if(!date){
        return;
      }
      console.log('startDate : ', this.startYmd)
			console.log('endDate : ', date);
      if(!this.startYmd){
        console.log('시작일자가 선택되지 않았음.');
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '검색 시작 일자가 선택되지 않았습니다.'});
        this.endYmd = '';
      }
      else if(date < this.startYmd){
        console.log('종료일자가 시작일자보다 작음.');
        this.$store.commit(`common/${MUT_SHOW_ALERT}`, {title: '검색 종료 일자는 시작 일자 이후로 선택 가능 합니다.'});
        this.endYmd = '';
      } else { 
        //console.log('종료일자가 시작일자보다 큼.');
      }
		},
  },
  computed: {
    ...mapGetters('common', ['isMobile']),
    refineItems() {
      return this.items.map(x => ({
        ntcId: x.ntcId,
        ntcTit: x.ntcTit,
        atchFileNm: x.atchFileNm,
        atchFilePtUrl: x.atchFilePtUrl,
        fileExt: x.fileExt,
        regDt: new Date(x.regDt),
        pageNo: this.pageNo,
        totalCount: this.totalCount,
        upporFixYn: x.upporFixYn,
        viewLink: this.$route.query.ctgrCode ? `/notice/${x.ntcId}/view?ctgrCode=${this.$route.query.ctgrCode}` : `/notice/${x.ntcId}/view`,
      }));
    },
    isNoResult() {
      return this.items.length === 0;
    },
    queries() {
      const result = {};
      if (this.keyword) result.keyword = this.keyword;
      if (this.ntcCtgrDcd) result.ctgrCode = this.ntcCtgrDcd;
      if (this.ntcTit) result.ntcTit = this.ntcTit;
      if (this.startYmd) result.startYmd = timestampToDateFormat(this.startYmd, 'yyyy-MM-dd');
      if (this.endYmd) result.endYmd = timestampToDateFormat(this.endYmd, 'yyyy-MM-dd');
      if (this.ntcTag) result.ntcTag = this.ntcTag;
      return result;
    },
  },
  created() {
    this.pageNo = queryToValue(this.$route.query.pageNo, true);
    this.ntcTit = queryToValue(this.$route.query.tit, false);
    this.ntcCtgrDcd = queryToValue(this.$route.query.ctgrCode, false, '');
    this.ntcTag = queryToValue(this.$route.query.tag, false);
    this.startYmd = queryToValue(this.$route.query.startYmd, false);
    this.endYmd = queryToValue(this.$route.query.endYmd, false);
    this.getNoticeCtgrList();
  },
  methods: {
    getNoticeCtgrList() {
      this.$store.dispatch(`common/${ACT_GET_COMMON_CODE_LIST}`, {
        dcd: '1020',
        masterYn: 'N',
      })
          .then(res => {
            tryResCallback(this.tryCount, res, this.getNoticeCtgrList, this.setNoticeCtgr);
          })
          .catch(e => {
            console.error(e);
          });
    },
    setNoticeCtgr(res) {
      if (lengthCheck(res)) this.ctgrItems = getItems(res);
      this.getNoticeList(true);
    },
    getNoticeList(isInit) {
      if (isInit) {
        this.items = [];
        this.isLoading = true;
      }
      if(this.startYmd) this.startYmd.setDate(this.startYmd.getDate()+1)
      if(this.endYmd) this.endYmd.setDate(this.endYmd.getDate()+2)


      this.$store.dispatch(`notice2/${ACT_GET_NOTICE2_LIST}`, {
        ntcTit: this.ntcTit,
        ntcTag: this.ntcTag,
        ntcCtgrDcd: this.ntcCtgrDcd,
        sortCd: this.sortCd,
        startYmd: this.startYmd,
        endYmd: this.endYmd,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      })
          .then(res => {
            if (lengthCheck(res)) {
              this.items = this.items.concat(getItems(res));
              setPaging(this, res);
            } else {
              setPaging(this, res);
            }
            this.isLoading = false;
          })
          .catch(e => {
            console.error(e);
            this.isLoading = false;
          });
    },
  },
};
</script>
